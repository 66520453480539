import { defineStore } from 'pinia';

interface IUnsavedChangesStore {
  hasUnsavedChanges: boolean;
  warningMessage: string;
  discardChangesCallback: () => void;
}

export const useUnsavedChangesStore = defineStore('unsavedChanges', {
  state: (): IUnsavedChangesStore => ({ 
    hasUnsavedChanges: false,
    warningMessage: "",
    discardChangesCallback: () => {},
  }),
  getters: {
    getUnsavedChangesState(state){
      return state.hasUnsavedChanges;
    }
  },
  actions: {
    setUnsavedChanges(discardChangesCallback: () => void, warningMessage: string) {
      this.discardChangesCallback = discardChangesCallback;
      this.hasUnsavedChanges = true;
      this.warningMessage = warningMessage;
    },
    clearUnsavedChanges() {
      this.hasUnsavedChanges = false;
    },
    showUnsavedChangesDialog(): Promise<boolean> {
      return new Promise((resolve) => {
        this.$onAction(({name, args})=> {
          if (name === 'handleDialogActions'){
            if (!args[0]) this.discardChangesCallback();
            resolve(args[0]);
          }
        });
      });
    },
    // discard changes - false, continue editing - true
    handleDialogActions(result: boolean) { 
      if (!result) {
        this.hasUnsavedChanges = false;
      }
    }
  },
});