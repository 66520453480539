<template>
  <button
    :clear="clear"
    :class="{ clear: clear }"
    :style="`flex-direction: ${
      iconLocation === 'right' ? 'row-reverse' : 'row'
    }`"
  >
    <span :class="[{ hide: !icon }, icon, iconSize, 'icon']"></span>
    <slot></slot>
  </button>
</template>
<script setup lang="ts">
import { AppColors } from "@/constants/colors";
import { computed } from "vue";

interface Props {
  icon?: string;
  iconLocation?: "left" | "right";
  clear?: boolean;
  iconSize?: "small" | "medium" | "large" | "medium-small";
  fullColor?: boolean;
  fullColorHover?: boolean;
  color?: keyof typeof AppColors;
  textColor?: keyof typeof AppColors;
}
const props = withDefaults(defineProps<Props>(), {
  icon: "",
  iconLocation: "left",
  clear: false,
  fullColor: false,
  fullColorHover: false,
  color: "buttonDefaultBackground",
  textColor: "MauveLight11",
});
const buttonMainColor = computed(() => AppColors[props.color]);
const textColorText = computed(() => AppColors[props.textColor]);

const fullColorHoverIntensity = computed(() =>
  props.fullColorHover ? "100%" : "80%"
);
</script>
<style scoped lang="scss">
.small {
  font-size: var(--font-size-xxs);
}
.medium {
  font-size: var(--font-size-md);
}
.large {
  font-size: var(--font-size-xxl);
}
.medium-small {
  font-size: 11px;
}
.hide {
  display: none;
}

button {
  --button-main-color: v-bind(buttonMainColor);
  --text-colour-text-contrast: v-bind(textColorText);
  display: inline-flex;
  text-align: center;
  height: 32px;
  justify-content: center;
  padding: var(--button-padding, 8px);
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background-color: var(--button-main-color);
  color: var(--text-colour-text-contrast, #fff);
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: solid 1px var(--button-border-color, transparent);
  gap: 8px;
  transition: all 0.2s ease-in-out;
}
button.clear {
  background: transparent;
}
button:hover {
  background: color-mix(
    in srgb,
    var(--button-main-color, var(--primary-color))
      v-bind(fullColorHoverIntensity),
    white
  );
}
button:active {
  background: color-mix(
    in srgb,
    var(--button-main-color, var(--primary-color)) 70%,
    white
  );
}
button:focus {
  outline: solid 2px var(--spokn-brand-border-color);
  background: color-mix(
    in srgb,
    var(--button-main-color, var(--primary-color)) 70%,
    white
  );
}
button:disabled {
  background: #30004010;
  color: #08003145;
  cursor: not-allowed;
}

// slotted icon
button::slotted(.icon) {
  margin-right: 8px;
  margin-left: 8px;
}
</style>
