import { defineStore } from "pinia";

interface Notification {
    id: string;
    message: string;
    title: string;
    type: "success" | "error";
}

export const useNotificationStore = defineStore("notification", {
  state: () => ({
    notifications: [] as Notification[],
   }),
  actions: {
    addNotification(notification: Notification) {
        notification.id = generateId();
        this.notifications.push(notification);
    },
    removeNotification(id: string) {
        this.notifications = this.notifications.filter((n) => n.id !== id);
    },
  },
});

function generateId() {
  return Math.random().toString(36).substring(2, 9);
}